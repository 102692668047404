
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import LiveOnLive from '@/components/live-stream/LiveOnLive.vue'
import { useRoute, useRouter } from 'vue-router'
import { checkLiveStatus, editLiveProduct, endLive, getLiveDetail, getLiveOrder, restartLive } from '@/api/live/live.api'
import LiveProductsTable from '@/components/live-stream/LiveProductsTable.vue'
import { userCurrency } from '@/use/userCurrency'
import { ORDER_STATUS_CHANNEL } from '@/constants/orderEnum'
import { ElMessageBox, ElNotification, ElLoading } from 'element-plus'
import { LIVE_STATUS, Product } from '@/api/live/live.type'
import { View } from '@element-plus/icons'
import { io, Socket } from 'socket.io-client'
import { baseURL } from '@/config'
import { toRefs } from '@vueuse/shared'
import { deleteOrderById } from '@/api/order/order.service'
import { useLiveStore } from '@/pinia/live/live.pinia'
import { useFacebookPageStore } from '@/pinia/facebook-page/facebook-page.pinia'
import { useWindowSize } from '@/use/useWindowSize'

export default defineComponent({
	name: 'LiveStreamId',
	components: {
		LiveOnLive,
		LiveProductsTable,
		View,
	},
	setup() {
		const facebookPageStore = useFacebookPageStore()
		const liveStore = useLiveStore()
		const router = useRouter()
		const route = useRoute()
		const liveProductTableRef = ref()
		const selectLiveDialogRef = ref()
		const liveList = ref([])
		const { currencyMoneyDigits } = userCurrency()
		const isEndingLivePress = ref(false)
		const { mobileMode: isMobile } = useWindowSize()
		const state = reactive({
			liveName: '',
			liveTitle: '',
			liveVideoUrl: '',
			liveStatus: null,
			originalDetail: null,
		})
		const isFetchingDetail = ref(false)
		const orderSocket = ref<Socket|null>(null)
		const productSocket = ref<Socket|null>(null)
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const tabs = ref([
			{
				index: 'live',
				name: 'ไลฟ์'
			},
			{
				index: 'product',
				name: 'จัดการสินค้า'
			},
			{
				index: 'orderManage',
				name: 'จัดการออเดอร์'
			}
		])
		const activeLiveTab = ref('live')
		
		watch(
			() => activeLiveTab.value,
			(tab) => {
				if (tab === 'product') {
					// fetch again
					fetchLiveDetail()
				}
			}
		)

		const activeOrderTab = ref('all')

		watch(
			() => activeOrderTab.value,
			() => fetchOrder()
		)

		const isFetchingOrder = ref(false)
		const fetchingOrderParams = reactive({
			offset: 1,
			limit: 10,
		})
		const order = reactive({
			totals: 0,
			list: [] as Array<any>
		})

		const currentPage = computed(() => facebookPageStore.findPageByPageId(route.params.pageId as string))
		const isLiveProductSameWithMaster = computed(() => liveStore.isLiveProductAndLiveProductMasterEqual)
		const totalProducts = computed(() => liveStore.liveProducts.reduce((sum, product:any) => sum + product.sold, 0))
		const totalSales = computed(() => liveStore.liveProducts.reduce((sum, product:any) => sum + product.sold * product.price, 0))

		const checkProductChange = async (activeName: string) => {
			if (activeName === 'live' && !isLiveProductSameWithMaster.value) {
				const isConfirm = await ElMessageBox.confirm(
					'คุณยังไม่ได้บันทึกการเปลี่ยนแปลงสินค้า คุณต้องการออกจากหน้านี้ใช่หรือไม่ ?',
					{
						confirmButtonText: 'ยืนยัน',
						cancelButtonText: 'ยกเลิก',
						type: 'warning',
						showClose: !isReactNativeWebview.value
					}
				)
				
				return isConfirm
			}
		}

		const fetchLiveDetail = async() => {
			try {
				isFetchingDetail.value = true
				const { data: liveDetail } = await getLiveDetail(route.params.liveId as string)
				state.liveVideoUrl = liveDetail.liveUrl
				state.liveStatus = liveDetail.status
				state.liveName = liveDetail.name
				state.liveTitle = liveDetail.title
				const liveProduct = liveDetail.products.map((product: any) => ({
					...product,
					images: product.product?.images,
					sku: product.product?.sku,
					_id: product.product?._id, // productId change key
					productObjectId: product._id, // _id changeKey
				}))
				// set original live detail
				state.originalDetail = liveDetail
				liveStore.updateLiveProductsMaster(liveProduct) // just initial for one time
				liveStore.updateLiveProducts(liveProduct)
			} catch (error) {
				console.log('error',error)
				
			}finally{
				isFetchingDetail.value = false
			}
		}

		const fetchOrder = () => {
			isFetchingOrder.value = true
			getLiveOrder(
				route.params.liveId as string,
				{
					offset: fetchingOrderParams.offset,
					limit: fetchingOrderParams.limit,
					status: activeOrderTab.value === 'all' ? null : activeOrderTab.value
				}
			)
				.then(({ data }) => {
					order.list = data.orders.map((order: any) => ({
						...order,
						// Hack for map same column with parent
						children: order.products.map((product: any) => ({
							image: product.product.images?.[0],
							name: product.product.parent
								? mapSubProductName(product?.product)
								: product.product.name,
							cost: product.price,
							unit: product.unit,
							sku: product.product.sku
						}))
					}))
					order.totals = data.pagination.totals
				})
				.finally(() => isFetchingOrder.value = false)
		}

		const onEndLive = () => {
			//
			ElMessageBox.confirm(
				'จบการถ่ายทอดสด?',
				{
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'warning',
					showClose: !isReactNativeWebview.value
				}
			).then(() => {
				isEndingLivePress.value = true
				endLive(route.params.liveId as string)
					.then(() => {
						ElNotification({
							title: 'สำเร็จ',
							message: 'การถ่ายทอดสดสิ้นสุดแล้ว',
							type: 'success',
							showClose: !isReactNativeWebview.value
						})
						router.push({ path: `/live-stream/${route.params.pageId}`, query: { status: 'COMPLETED' } })
					})
					.finally(() => isEndingLivePress.value = false)
			})

		}

		const onDeleteOrder = (order: any) => {
			ElMessageBox.confirm(
				'ต้องการลบคำสั่งซื้อใช่หรือไม่ ?',
				{
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'warning',
					showClose: !isReactNativeWebview.value
				}
			)
				.then(() => {
					deleteOrderById(order._id)
						.then(() => {
							ElNotification({
								title: 'สำเร็จ',
								message: 'ลบคำสั่งซื้อสำเร็จ',
								type: 'success',
								showClose: !isReactNativeWebview.value
							})
							// refetch
							fetchOrder()
						})
				})
				.catch((error) => {
					console.log('[ERROR]: ', error)
				})
		}

		const onEditLiveProduct = async () => {
			const loading = ElLoading.service({
				lock: true,
				text: 'ระบบกำลังประมวลการทำรายการของท่าน กรุณารอสักครู่...',
				background: 'rgba(0, 0, 0, 0.15)',
			})
			try {
				const isValid = await liveProductTableRef.value.validateAll()
				if (!isValid) return
				//
				const editPayload: Product[] = liveStore.liveProducts.map((product: any) => ({
					product: product._id,
					price: product.price,
					cfCode: product.cfCode,
					enable: product.enable,
					quantity: product.quantity,
				}))
				await editLiveProduct(route.params.liveId as string, editPayload)
				await fetchLiveDetail()
			
				ElNotification({
					title: 'สำเร็จ',
					message: 'แก้ไขสินค้าสำเร็จ',
					type: 'success',
					showClose: !isReactNativeWebview.value
				})
			} catch (error) {
				console.log('error',error)
			} finally {
				loading.close()
			}
			
		}

		const resetLiveWithNewLive = async () => {
			//
			const isConfirm = await ElMessageBox.confirm(
				'ต้องการเชื่อมต่อการถ่ายทอดสดอีกครั้งหรือไม่ ?',
				{
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'warning',
					showClose: !isReactNativeWebview.value
				}
			)

			if (!isConfirm) return

			// restart live
			isFetchingDetail.value = true
			try {
				const { data: { liveSteamingPosts } } = await checkLiveStatus(currentPage.value?._id as string, route.params.liveId as string)
				if (liveSteamingPosts.length < 1) {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ไม่สามารถเริ่มการถ่ายทอดสดได้ เนื่องจาก เพจของคุณยังไม่ได้มีการถ่ายทอดสด กรุณาไปที่เพจแล้วทำการถ่ายทอดสดอย่างน้อย 1 รายการ',
						type: 'error',
						showClose: !isReactNativeWebview.value
					})
				} else {
					if (liveSteamingPosts.length > 1) {
						// Waiting confirm Dialog
						liveList.value = liveSteamingPosts
						const { liveStreamId, videoId, title } = await selectLiveDialogRef.value.showDialog()
						// Start Live
						const liveId = route.params.liveId as string
						await restartLive(liveStreamId, liveId, videoId, title)
					}
					// === 1
					if (liveSteamingPosts.length === 1) {
						// Start Live
						const liveId = route.params.liveId as string
						const liveVideo = liveSteamingPosts?.[0]
						await restartLive(liveVideo.id, liveId, liveVideo.video?.id, liveVideo.title)
					}
					// clear initial Data
					orderSocket.value?.close()
					productSocket.value?.close()

					fetchLiveDetail()
					fetchOrder()
					subScribeSocketOrder()
					subScribeProductSocket()

				}
				
			} catch (error: any) {
				console.log('[ERROR]: ', error)
				if (error.response?.data.resDesc === 'LIVE_STREAM_DUPLICATE') {
					ElNotification({
						title: 'ไม่สำเร็จ',
						message: 'ไม่สามารถรีสตาร์ทการถ่ายทอดสดเดิมได้ กรุณาตรวจสอบการถ่ายทอดสดอีกครั้ง',
						type: 'error',
						showClose: !isReactNativeWebview.value
					})
				}
			} finally {
				isFetchingDetail.value = false
			}
		}

		const onOpenOrderDetail = (order: any) => {
			window.open(
				`/order/detail?orderID=${order._id}`
			)?.focus()
		}

		const subScribeSocketOrder = () => {
			
			if (state.liveStatus === LIVE_STATUS.COMPLETED) return

			orderSocket.value = io(`${baseURL}order`)
			orderSocket.value.emit('joinRoom', { room: route.params.liveId })
			// subscribe
			orderSocket.value.on('toClient', (newOrder: any) => {
				const mapNewOrderDataWithChildren = {
					...newOrder,
					children: newOrder.products.map((product: any) => ({
						image: product.product.images?.[0],
						name: product.product.parent
							? mapSubProductName(product?.product)
							: product.product.name,
						cost: product.price,
						unit: product.unit,
						sku: product.product.sku
					}))
				}
				const findExistOrder = order.list.find(o => o._id === mapNewOrderDataWithChildren._id)
				if (findExistOrder) {
					order.list = order.list.map((o) => {
						if (o._id === findExistOrder._id) {
							return mapNewOrderDataWithChildren
						}
						return o
					})
				} else {
					// add new
					order.list.unshift(mapNewOrderDataWithChildren)
					order.totals++ // plus order
				}

			})
		}

		const subScribeProductSocket = () => {
			if (state.liveStatus === LIVE_STATUS.COMPLETED) return

			productSocket.value = io(`${baseURL}product`)
			productSocket.value.emit('joinRoom', { room: route.params.liveId })
			// subscribe
			productSocket.value.on('toClient', (productUpdate: any) => {
				//
				liveStore.updateSoldProductById(productUpdate)
			})
		}

		const mapSubProductName = (product:any) => {
			let types = product?.types
			if (product.marketplaces) {
				types = product.marketplaces[0].types
			}
			return product?.parent?.name + '(' + types.join('-') + ')'
		}

		const displayName = (row: any) => {
			//
			if (row.customer?.name) {
				return row.customer.name
			}
			if (row.name && row.sku) {
				return ''
			}
			return 'ไม่ระบุ'
		}

		onMounted(() => {
			fetchLiveDetail()
			fetchOrder()
			subScribeSocketOrder()
			subScribeProductSocket()
		})

		onUnmounted(() => {
			orderSocket.value?.close()
			productSocket.value?.close()
		})

		return {
			ORDER_STATUS_CHANNEL,
			tabs,
			activeLiveTab,
			activeOrderTab,
			order,
			currentPage,
			fetchLiveDetail,
			...toRefs(state),
			isFetchingDetail,
			isFetchingOrder,
			currencyMoneyDigits,
			onEndLive,
			fetchOrder,
			isEndingLivePress,
			LIVE_STATUS,
			fetchingOrderParams,
			onDeleteOrder,
			onEditLiveProduct,
			onOpenOrderDetail,
			liveProductTableRef,
			mapSubProductName,
			displayName,
			resetLiveWithNewLive,
			selectLiveDialogRef,
			liveList,
			checkProductChange,
			liveStore,
			totalProducts,
			totalSales,
			isMobile
		}
	}
})
