import { IStatusOrderList } from '@/type/order'

export enum ORDER_EXPORT_NAME {
	SHIPPING_LABEL = 'ใบปะหน้า',
	PAYMENT_RECEIPT = 'ใบเสร็จชำระเงิน/ใบกำกับภาษี',
}

export enum ORDER_STATUS {
	DRAFT = 'DRAFT',
	PROCESS = 'PROCESS',
	PREPARE_SEND = 'PREPARE_SEND',
	SENT = 'SENT',
	COMPLETED = 'COMPLETED',
	REJECT = 'REJECT',
	CANCEL = 'CANCEL',
}

export enum ORDER_PAYMENT_CHANNEL {
	ALL = '',
	COD = 'COD',
	TRANSFER = 'TRANSFER',
}

export type OrderStatusChannelType = {
	[key in ORDER_STATUS]: {
		text: string
		color: string
	}
}

export enum ORDER_FILLIN_STATUS {
    WAITING_FILLIN = 'WAITING_FILLIN',
    WAITING_VERIFY = 'WAITING_VERIFY',
    PREPARE_SEND = 'PREPARE_SEND',
    SENT = 'SENT',
    COMPLETED = 'COMPLETED',
}

export type OrderFillinStatusType = {
	id: number
	label: string
	value: string
}

export const ORDER_FILLIN_STATUS_LIST: Record<ORDER_FILLIN_STATUS, OrderFillinStatusType> = {
	[ORDER_FILLIN_STATUS.WAITING_FILLIN]: {
		id: 1,
		label: 'กรอกที่อยู่จัดส่ง/ชำระเงิน',
		value: ORDER_FILLIN_STATUS.WAITING_FILLIN,
	},
	[ORDER_FILLIN_STATUS.WAITING_VERIFY]: {
		id: 2,
		label: 'กำลังตรวจสอบการชำระเงิน',
		value: ORDER_FILLIN_STATUS.WAITING_VERIFY,
	},
	[ORDER_FILLIN_STATUS.PREPARE_SEND]: {
		id: 3,
		label: 'เตรียมตัวจัดส่ง',
		value: ORDER_FILLIN_STATUS.PREPARE_SEND,
	},
	[ORDER_FILLIN_STATUS.SENT]: {
		id: 4,
		label: 'อยู่ระหว่างจัดส่ง',
		value: ORDER_FILLIN_STATUS.SENT,
	},
	[ORDER_FILLIN_STATUS.COMPLETED]: {
		id: 5,
		label: 'จัดส่งสำเร็จ',
		value: ORDER_FILLIN_STATUS.COMPLETED,
	},
}

export const ORDER_STATUS_CHANNEL: OrderStatusChannelType = {
	DRAFT: {
		text: 'ร่าง',
		color: 'info',
	},
	PROCESS: {
		text: 'คำสั่งซื้อรอดำเนินการ',
		color: '',
	},
	PREPARE_SEND: {
		text: 'เตรียมตัวจัดส่ง',
		color: 'warning',
	},
	SENT: {
		text: 'อยู่ระหว่างจัดส่ง',
		color: '',
	},
	COMPLETED: {
		text: 'จัดส่งสำเร็จ',
		color: 'success',
	},
	REJECT: {
		text: 'ตีกลับ',
		color: 'danger',
	},
	CANCEL: {
		text: 'ถูกยกเลิก',
		color: 'danger',
	},
}

export const STATUS_ORDER_LIST: Array<IStatusOrderList> = [
	{
		id: 1,
		label: 'ร่าง',
		value: 'DRAFT',
	},
	{
		id: 2,
		label: 'คำสั่งซื้อรอดำเนินการ',
		value: 'PROCESS',
	},
	{
		id: 3,
		label: 'เตรียมตัวจัดส่ง',
		value: 'PREPARE_SEND',
	},
	{
		id: 4,
		label: 'อยู่ระหว่างจัดส่ง',
		value: 'SENT',
	},
	{
		id: 5,
		label: 'พัสดุมีปัญหา',
		value: 'PROBLEM',
	},
	{
		id: 6,
		label: 'จัดส่งสำเร็จ',
		value: 'COMPLETED',
	},
	{
		id: 7,
		label: 'ตีกลับ',
		value: 'REJECT',
	},
	{
		id: 8,
		label: 'ถูกยกเลิก',
		value: 'CANCEL',
	},
]

export const STATUS_COURIER = {
	COD: 'เก็บเงินปลายทาง',
	TRANSFER: 'โอนเงิน',
}

export const BANK_LIST = [
	{
		name: 'กสิกร',
		code: 'KBANK',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/kbank-icon.webp',
	},
	{
		name: 'ไทยพาณิชย์',
		code: 'SCB',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/scb-icon.webp',
	},
	{
		name: 'กรุงไทย',
		code: 'KTB',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/ktb-icon.webp',
	},
	{
		name: 'กรุงศรี',
		code: 'BAY',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/krungsri-icon.webp',
	},
	{
		name: 'กรุงเทพ',
		code: 'BBL',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/bbl-icon.webp',
	},
	{
		name: 'ทีเอ็มบีธนชาต',
		code: 'TTB',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/ttb-icon.webp',
	},
	{
		name: 'ออมสิน',
		code: 'GSB',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/gsb-icon.webp',
	},
	{
		name: 'ยูโอบี',
		code: 'UOB',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/uob-icon.webp',
	},
	{
		name: 'ทิสโก้',
		code: 'TISCO',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/bank/tisco-icon.webp',
	},
]

export const COLOR_ORDER_STATUS_CHANNEL = {
	DRAFT: 'info',
	PROCESS: '',
	PREPARE_SEND: 'warning',
	SENT: '',
	COMPLETED: 'success',
	REJECT: 'danger',
	CANCEL: 'danger',
}

export const DEFAULT_ORDER = {
	createdAt: '',
	customer: {
		name: '',
		phoneNo: '',
	},
	deleted: false,
	deletedAt: null,
	discount: 0,
	products: [],
	shipment: {
		desc1: '',
		provinceCode: null,
		districtCode: null,
		subDistrictCode: null,
		zipCode: null,
		note: '',
		fee: 0,
	},
	payment: {
		channel: '',
		transferInfo: {
			slipImage: '',
			bankIssuerId: '',
			date: '',
		},
	},
	shopId: '',
	status: '',
	updatedAt: '',
	_id: '',
	condition: {
		isFillInCustomerInfo: false,
		isFillInPayment: false,
		isFillInShipmentAddress: false,
	},
	courier: {
		image: '',
		name: '',
		_id: '',
	},
	reference: '',
}

export const TYPE_SERVICE = {
	PICKUP: 'ให้รถเข้ารับ',
	DROP_OFF: 'นำส่งเองที่สาขา',
}

export enum ORDER_CONTACT_CHANNEL {
	LINE = 'LINE',
	INSTAGRAM = 'INSTAGRAM',
	X = 'X',
	FACEBOOK = 'FACEBOOK',
	WEBSITE = 'WEBSITE',
	ETC = 'ETC',
	TIKTOK = 'TIKTOK',
	LAZADA = 'LAZADA',
	SHOPEE = 'SHOPEE',
}

export const orderStatus = {
	default: {
		color: '#606266',
		backgroundColor: '#fff',
		borderColor: '#dcdfe6',
	},
	info: {
		color: '#909399',
		backgroundColor: '#f4f4f5',
		borderColor: '#c8c9cc',
	},
	primary: {
		color: '#409eff',
		backgroundColor: '#ecf5ff',
		borderColor: '#a0cfff',
	},
	success: {
		color: '#67c23a',
		backgroundColor: '#f0f9eb',
		borderColor: '##b3e19d',
	},
	warning: {
		color: '#e6a23c',
		backgroundColor: '#fdf6ec',
		borderColor: '#f3d19e',
	},
	danger: {
		color: '#f56c6c',
		backgroundColor: '#fef0f0',
		borderColor: '#fab6b6',
	}
}
export enum CREATE_ORDER_ERROR {
	IS_DUPLICATE_ORDER = 'IS_DUPLICATE_ORDER',
	COURIER_AREA_NOT_SUPPORT = 'COURIER_AREA_NOT_SUPPORT',
	CANNOT_EDIT_CONTACT_CHANNEL = 'CANNOT_EDIT_CONTACT_CHANNEL',
}
export enum ORDER_PAGE_ERROR {
	PAGE_ACCESS_TOKEN_HAS_EXPIRED = 'PAGE_ACCESS_TOKEN_HAS_EXPIRED',
	PAGE_NOT_FOUND = 'PAGE_NOT_FOUND'
}